<script setup lang="ts">

</script>

<template>
  <div class="h-[30px] bg-gray-600" />
</template>

<style lang="css" scoped>

</style>
