<script setup lang="ts">

</script>

<template>
  <div class="h-[30px] bg-yellow-400" />
</template>

<style lang="css" scoped>

</style>
